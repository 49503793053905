import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

function UtilityInfo() {
  return (
    <div className='bg-gray-800'>
      <div className={clsx('md:px-4 mx-auto lg:px-8 max-w-7xl')}>
        <h2 className='px-4 md:px-0 text-2xl font-extrabold text-neutral-300 md:text-4xl'>We've got a few other NFT utilities</h2>
        <div className='pb-3 md:pb-0 flex md:grid md:grid-cols-2 md:gap-10 mt-8 overflow-scroll'>
          <Utility
            heading='Shitty Adventures'
            image='/adventures/fishing-trip.png'
            href='/adventures'
            description='Send your kitties on Shitty Adventures to earn Shitty Treats.'
          />
          <Utility
            heading='Trait Swapper'
            image='/trait-swap.png'
            href='/trait-swapper'
            description='Swap traits between two of your Fourth Litter Shitty Kitties'
          />
          <Utility
            heading='Shitty City Builder'
            href='/city-builder'
            description={`Make your Shitty Cities even shittier.`}
            image='/shitty-city.png'
          />
          <Utility
            heading='Shitty Vending Machine'
            image='/vending-machine.png'
            href='/vending-machine'
            description='Spend Shitty Treats for an NFT from the Shitty Vending Machine.'
          />
          <Utility
            heading='Shitty Raffles'
            href='/raffles'
            description='Enter raffles using Shitty Treats.'
            image='/blocks/minified/1102790446.png'
          />
          <Utility
            heading='Meme Generator'
            href='/meme-generator'
            description={`Create memes using your Shitty Kitties.`}
            image='/meme-preview.jpeg'
          />
        </div>
      </div>
    </div>
  );
}

const Utility = ({ image, heading, href, description }) => {
  return (
    <div className='md:w-full min-w-[300px] max-w-[300px] md:max-w-none md:min-w-0 aspect-[2/1] relative overflow-hidden rounded-lg group mx-4 md:mx-0'>
      <Link href={href}>
        <a className='absolute inset-0 z-10'>
          <div className='absolute inset-0 z-20 bg-gray-900 bg-opacity-90 group-hover:bg-opacity-75 transition duration-300 '></div>
          {image && <Image layout='fill' src={image} alt={heading} className='absolute inset-0 z-10 object-cover w-full h-full' />}
          <div className='absolute inset-0 z-30 px-5 md:px-3 text-center w-full h-full flex items-center justify-center flex-col'>
            <h3 className='font-bold text-xl md:text-2xl w-full'>{heading}</h3>
            <p className='text-gray-400 text-base md:text-xl max-w-sm mx-auto mt-2'>{description}</p>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default UtilityInfo;
