import Collections from '../components/home/Collections';
import FloorBounties from '../components/home/FloorBounties';
import HomeHero from '../components/home/HomeHero';
import TopSales from '../components/home/TopSales';
import TraitSwaps from '../components/home/TraitSwaps';
import UtilityInfo from '../components/home/UtilityInfo';

export default function Home() {
  return (
    <>
      <HomeHero />
      <div className='bg-gray-800 flex flex-col gap-24 pt-24 pb-48'>
        <Collections />
        <FloorBounties />
        <UtilityInfo />
        <TopSales />
      </div>
    </>
  );
}
