import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import React from 'react';
import useNfd from '../../hooks/useNfd';
import formatNumber from '../../utils/formatNumber';
import { getAsset } from '../../utils/getAsset';
import NftImage from '../NftImage';
import ProfileAvatar from '../ProfileAvatar';

dayjs.extend(relativeTime);

const TopSales = () => {
  const { data } = useQuery({
    queryKey: ['topSales'],
    queryFn: () => fetch('/api/top-sales/list').then((res) => res.json()),
  });
  const sales = data || [];

  return (
    <div className='bg-gray-800'>
      <div className={clsx('px-4 mx-auto lg:px-8 max-w-7xl')}>
        <div>
          <h2 className='text-2xl font-extrabold text-neutral-300 md:text-4xl'>Top Sales</h2>
          <p className='mt-4 text-lg text-neutral-400'>View the top sales in the last month.</p>
        </div>
      </div>

      <div className={clsx('mx-auto md:px-4 lg:px-8 max-w-7xl')}>
        <div className='mt-8'>
          <div className='flex md:grid grid-cols-2 md:gap-8 overflow-scroll md:overflow-visible pb-3 md:pb-0'>
            {sales.map((sale) => (
              <TopSale key={sale._id} sale={sale} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TopSale = ({ sale }) => {
  const { nfd } = useNfd({ address: sale.buyer });
  const { data: info } = useQuery({
    queryKey: ['topSale', sale.asa_id],
    queryFn: async () => {
      const asset = await getAsset(sale.asa_id);
      return { name: asset.params.name };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
  const theName = info?.name;
  const theImage = info?.image;
  const theWallet = nfd?.name ? nfd?.name : `${sale.buyer?.slice(0, 4)}...${sale.buyer?.slice(-4)}`;

  const date = dayjs.unix(sale.time).fromNow();

  return (
    <div key={sale._id} className='mx-4 md:mx-0 min-w-[250px] md:min-w-0 max-w-[250px] md:max-w-none'>
      <a
        href={`https://www.asalytic.app/nft/${sale.asa_id}`}
        target='_blank'
        className='flex flex-col xl:flex-row relative bg-neutral-900 rounded-lg overflow-hidden'
      >
        <div className='text-[0px] aspect-square relative xl:w-[200px] flex-shrink-0'>
          {sale && <NftImage nft={sale} width={400} height={400} className='absolute inset-0 object-cover w-full h-full bg-gray-700' />}
        </div>

        <div className='flex flex-col w-full gap-3 p-8 justify-center'>
          <div className='flex flex-col gap-1.5'>
            <div>
              <h2 className='font-bold flex flex-col text-neutral-50'>
                <span>{theName}</span>
              </h2>
            </div>
            <div className='w-full'>
              <p className='flex flex-col md:flex-row gap-2'>
                <span className='flex gap-2'>
                  <span className='text-neutral-400'>Sold for</span>
                  <span className='font-bold inline-flex gap-2'>
                    <span className='inline-flex items-center justify-center w-5 h-5 p-1 bg-primary-500 rounded-full'>
                      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113 113.4' className='w-full h-full'>
                        <polygon points='19.6 113.4 36 85 52.4 56.7 68.7 28.3 71.4 23.8 72.6 28.3 77.6 47 72 56.7 55.6 85 39.3 113.4 58.9 113.4 75.3 85 83.8 70.3 87.8 85 95.4 113.4 113 113.4 105.4 85 97.8 56.7 95.8 49.4 108 28.3 90.2 28.3 89.6 26.2 83.4 3 82.6 0 65.5 0 65.1 0.6 49.1 28.3 32.7 56.7 16.4 85 0 113.4 19.6 113.4'></polygon>
                      </svg>
                    </span>
                    <span>{formatNumber(sale.price.algo)}</span>
                  </span>
                </span>
                <span className='text-neutral-400'>{date}</span>
              </p>
            </div>
          </div>
          <div>
            <Link href={`/profile/${sale?.buyer}`}>
              <a className='flex items-center'>
                <div className='flex items-center mr-2'>
                  <ProfileAvatar width={25} address={sale.buyer} />
                </div>

                <span>{theWallet}</span>
              </a>
            </Link>
          </div>
        </div>
      </a>
    </div>
  );
};

export default TopSales;
